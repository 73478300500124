"use strict";

class Handlers {

  constructor(){

  }

  init()
  {
    this.bindUI();
  }

  bindUI(){

    let homeEl = document.querySelector(".nav__logoContainer");
    let aboutEl = document.querySelector(".nav__about");
    let contactEl = document.querySelector(".nav__contact");
    let hamburgerEl = document.querySelector(".nav__hamburger");
    let mobileNav = document.querySelector(".nav__mobile");
    let nav__mobileLogo = document.querySelector(".nav__mobileLogo");
    let moreworkArrow__right = document.querySelector(".morework__arrow-right");
    let moreworkArrow__left = document.querySelector(".morework__arrow-left");


    aboutEl.addEventListener("click", (e)=>{
      e.preventDefault();
      this.gotoPage(e);
    });

    contactEl.addEventListener("click", (e)=>{
      e.preventDefault();
      this.gotoPage(e);
    });

    homeEl.addEventListener("click", (e)=>{
      if( window.location.pathname != "/" ){
        e.preventDefault();
        this.gotoPage(e);
      }
    });

    hamburgerEl.addEventListener("click", ()=>{
      hamburgerEl.classList.toggle("nav__hamburger--is-active");
      mobileNav.classList.toggle("nav__mobile--is-active");
    });

    nav__mobileLogo.addEventListener("click", (e)=>{
      e.preventDefault();
      this.gotoPage("/");
    })

    if(moreworkArrow__right){
      moreworkArrow__left.addEventListener("click", ()=>{
        this.moreworkCarousel("left");
      });

      moreworkArrow__right.addEventListener("click", ()=>{
        this.moreworkCarousel("right");
      });
    }

    if(document.querySelector(".home-video")){
      this.heroBackgroundVideo("home");
    }

    if(document.querySelector(".about-video")){
      this.heroBackgroundVideo("about");
    }

    if(document.querySelector(".contact-video")){
      this.heroBackgroundVideo("contact");
    }

  }

  bindFilters(){
    let subnavEls = document.querySelectorAll(".subnavEl");
    for(let i = 0; i < subnavEls.length; i++){

      let count = i;

        subnavEls[i].onclick = (function(i){
          return function(){
            handlers.homepageFilterHandlers(i);
          }
        }(i))

    }
  }

  moreworkHandlers(){
    let moreWorkEls = document.querySelectorAll(".morework__workEL");
    for(let i = 0; i < moreWorkEls.length; i++){
      moreWorkEls[i].addEventListener("click", (e)=>{
        e.preventDefault();
        this.gotoPage(e.target.dataset.hash);
      })
    }
  }

  workElHandlers(){
    let workEls = document.querySelectorAll(".work__element");
    if(workEls){
      for( var i = 0; i < workEls.length; i++ ){
        let currentEL = workEls[i];
        currentEL.addEventListener("click", (e) => {
          e.preventDefault();
          let hash = e.target.dataset.hash;
          this.gotoPage(`/work.html${hash}`);
        });
      }
    }
  }


  gotoPage(evt){
    let url;
    if(evt.target){
      url = evt.target.dataset.url;
    }else{
      url = evt;
    }

    document.body.classList.add("fade-out");

    setTimeout(()=>{
      if(window.location.href.indexOf("work") != -1 && url.indexOf("#") != -1){
        window.location = url;
        window.location.reload();
      }else{
        window.location = url;
      }

    }, 2000);
  }


  homepageFilterHandlers(count){
    let filters = render.pageData.home.filters;
    let subnavEls = document.querySelectorAll(".subnavEl");
    let currentActive = document.querySelector(".subnavEl--is-active")

    currentActive.classList.remove("subnavEl--is-active");
    subnavEls[count].classList.add("subnavEl--is-active");

    render.currentFilter = filters[count].toLowerCase();

    render.renderHomepageWork();
  }


  moreworkCarousel(direction){
    let workParent = document.querySelector(".morework__listEl");
    let workEls = document.querySelectorAll(".morework__workEL");

    if(direction == "right"){

      // Start slide animation
      workParent.classList.add("morework__listEl--scroll-right");

      // Cycle through the work elements
      setTimeout(()=>{
        for(var i = 0; i < 3; i++){
          workParent.appendChild(workEls[i]);
        }
      }, 500)

      // Remove animation and reset for next click
      setTimeout(()=>{
        workParent.classList.remove("morework__listEl--scroll-right");
      }, 1000)



    }else{

      workParent.classList.add("morework__listEl--scroll-left");

      setTimeout(()=>{
        for(var i = 0; i < 3; i++){
          workEls = document.querySelectorAll(".morework__workEL");
          workParent.insertBefore( workEls[workEls.length-1], workEls[0]);
        }
      }, 500)

      setTimeout(()=>{
        workParent.classList.remove("morework__listEl--scroll-left");
      }, 1000)


    }
  }


  heroBackgroundVideo(page){
    let heroContainer = document.querySelector(".hero__background");
    let videoEl = document.querySelector(".hero__backgroundVideo");
    let mp4Source = document.createElement("SOURCE"),
        webmSource = document.createElement("SOURCE"),
        backup = document.createElement("IMG");

    mp4Source.setAttribute("src", `/lib/video/${page.toLowerCase()}.mp4`);
    webmSource.setAttribute("src", `/lib/video/${page.toLowerCase()}.webm`);
    backup.setAttribute("src", `/lib/video/${page.toLowerCase()}.gif`);

    mp4Source.setAttribute("type", "video/mp4");
    webmSource.setAttribute("type", "video/webm");

    videoEl.appendChild(mp4Source);
    videoEl.appendChild(webmSource);
    videoEl.appendChild(backup);

    videoEl.load();
    videoEl.oncanplay = ()=>{
      setTimeout(()=>{
        videoEl.classList.remove("hero__backgroundVideo--is-hidden")
      }, 1000)
    };
  }


}
