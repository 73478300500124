"use strict";

class Animate {

  constructor(){
    this.lastScroll = 0;
  }

  init()
  {

    this.animateIntro();
    this.bindUI();

    if( document.querySelector(".index") ){
      this.setupIndexWaypoints();
    }

    smoothScroll.init();

    let logo = new Waypoint({
      element: document.querySelector(".hero"),
      handler: function(direction){
        document.querySelector(".nav__logoContainer").classList.toggle("nav__logoContainer--collapsed");
      },
      offset: "-5%"
    });

  }


  bindUI()
  {

    window.addEventListener("scroll", ()=>{
      window.requestAnimationFrame( ()=>{
        this.scrollHandler()
      });
    })

  }


  animateIntro(){
    let bodyEl = document.body;
    let logoContainer = document.querySelector(".nav__logoContainer");
    setTimeout(()=>{ bodyEl.classList.remove("intro-animation"); }, 100);
    setTimeout(()=>{
      bodyEl.classList.remove("is-locked");
      logoContainer.classList.remove("nav__logoContainer--initial-delay");
    }, 2000);
  }


  scrollHandler(){
    var newScroll = window.pageYOffset || document.documentElement.scrollTop;
    if (newScroll > this.lastScroll){
      this.logoScrollDown();
    } else {
      this.logoScrollUp();
    }
    this.workImageParallax();
    this.lastScroll = newScroll;
  }



  logoScrollDown(){
    if( document.body.classList.contains("filter-locked") ){
      document.body.classList.add("logo-hidden");
    }
  }

  logoScrollUp(){
    document.body.classList.remove("logo-hidden");
  }


  workImageParallax(){
    let workImageEls = document.querySelectorAll(".work__imageEl"),
        workImageContainer = document.querySelectorAll(".work__image"),
        windowHeight = window.innerHeight;

    for(let i = 0; i < workImageEls.length; i++){
      let distance = (workImageContainer[i].offsetHeight - workImageEls[i].offsetHeight) / 100;
      let percentage = (workImageContainer[i].getBoundingClientRect().top / windowHeight) * 100;
      workImageEls[i].style.transform = "scale(1.0) translateY(-"+ (distance * percentage) * 8 +"px)";
    }

  }


  setupIndexWaypoints(){
    let bodyEl = document.body;
    let workEls = document.querySelectorAll(".work__element");
    let subnavEl = document.querySelector(".subnav__wrapper")
    let hamburgerEL = document.querySelector(".nav__hamburger")
    let filter;

    if( !document.querySelector(".hero--contact") ){
      filter = new Waypoint({
        element: document.querySelector(".subnav__wrapper"),
        handler: function(direction) {
          if(direction == "down"){
            bodyEl.classList.add("filter-locked");
          }else{
            bodyEl.classList.remove("filter-locked");
          }
        },
        offset: 80
      });
    }



    if( !document.querySelector(".hero--contact") ){
      for(let i = 0; i < workEls.length; i++){
        let work = new Waypoint({
            element: workEls[i],
            handler: (direction)=>{
              workEls[i].classList.remove("work__element--hidden");
            },
            offset: "75%"
          }
        );
      }
    }

    hamburgerEL.classList.remove("nav__hamburger--is-visible");

    let hamburger = new Waypoint({
      element: document.querySelector(".hero"),
      handler: function(direction){
        hamburgerEL.classList.add("nav__hamburger--is-visible");
      },
      offset: "-150px"
    });


    let mobileFilter = new Waypoint({
      element: document.querySelector(".subnav"),
      handler: function(direction){
        let navEl = document.querySelector(".subnav__filterTitleEl");
        if(direction == "down"){
          navEl.classList.add("subnav__filterTitleEl--is-active");
        }else{
          navEl.classList.remove("subnav__filterTitleEl--is-active");
        }
      },
      offset: "-35%"
    });




  }


  setupWorkWaypoints(){
    let bodyEl = document.body;
    let workImages = document.querySelectorAll(".work__image");
    let filter;

    if( workImages ){
      for(let i = 0; i < workImages.length; i++){
        let workImg = new Waypoint({
            element: workImages[i],
            handler: (direction)=>{
              workImages[i].classList.remove("work__image--hidden");
            },
            offset: "75%"
          }
        );
      }
    }
  }


  rerenderHomepageWork(){

  }


}
