"use strict";

class Render {

  constructor(){
    this.pageData = utils.get("./lib/data/pageData.json");
    this.currentFilter = "view all";
    this.currentPageIndex = null;
  }

  init()
  {
    this.checkPageData();
    window.scrollTo(0,0);
  }

  setPageData(data){
    this.pageData = data;
  }

  checkPageData(){
    if(this.pageData){
        this.pageData = JSON.parse(this.pageData);
        this.renderData(this.pageData);
    }else{
      utils.get("/lib/data/pageData.json");
      setTimeout(()=>{
        this.checkPageData();
      }, 250);
    }
  }

  renderData(){

    if(document.body.classList.contains("index")){
      this.renderHomepageHero();
      this.renderHomepageFilters();
      this.renderHomepageWork();
    }

    if(document.body.classList.contains("work")){
      this.renderWorkPage();
    }

    if(document.body.classList.contains("about")){
      this.renderAboutPage();
    }

    if(document.body.classList.contains("contact")){
      this.renderContactPage();
    }

    if(document.querySelector(".morework__list")){
      this.renderMoreWorkEl();
    }

  }


  renderHomepageHero(){
    let renderEl = render.pageData.home;
    let hero__headline = document.querySelector(".hero__headline");
    let hero__subline = document.querySelector(".hero__subline");
    hero__headline.innerHTML = renderEl.hero__headline;
    hero__subline.innerHTML = renderEl.hero__subline;
  }

  renderHomepageFilters(){
    let filterEl = document.querySelector(".subnav__wrapper");
    for(var i = 0; i < render.pageData.home.filters.length; i++){
      let html;
      if(i == 0){
        html = `<li class="subnavEl subnavEl--is-active">${render.pageData.home.filters[i]}</li>`;
      }else{
        html = `<li class="subnavEl">${render.pageData.home.filters[i]}</li>`;
      }
      filterEl.innerHTML += html;
    }
    handlers.bindFilters();
  }

  renderHomepageWork(){
    let renderEl = render.pageData.work;
    let modifiedEl = [];
    let workWrapper = document.querySelector(".workmain");
    let workElTemplate;

    workWrapper.innerHTML = "";

    if(  this.currentFilter != "view all" ){

      for(let j = 0; j < renderEl.length; j++){

        for( let k = 0; k < renderEl[j].filters.length; k++ ){

          if( renderEl[j].filters[k].toLowerCase() == this.currentFilter ){

            modifiedEl.push( renderEl[j] );

          }
        }

      }

    }else{
      modifiedEl = renderEl;
    }

    for(let i = 0; i < modifiedEl.length; i++){
      workElTemplate = `
        <article class="work__element work__element--hidden" data-hash="#${modifiedEl[i].pagetitle}">
          <div class="work__descWrapper">
            <div class="work__desc">
              <h1>${modifiedEl[i].title}</h1>
              <ul>`;

              for(var j = 0; j < modifiedEl[i].filters.length; j++){
                workElTemplate += `<li>${modifiedEl[i].filters[j]}</li>`
              }

              workElTemplate +=
              `</ul>
            </div>
          </div>
          <div class="work__imageWrapper">
            <figure class="work__image">
              <img class="work__imageEl" alt="${modifiedEl[i].thumbnailAltText}" src="${modifiedEl[i].thumbnailImagePath}"/>
            </figure>
            <svg class="work__logo" style="width: ${modifiedEl[i].logoSVGPositioning.width}; height: ${modifiedEl[i].logoSVGPositioning.height}; bottom: ${modifiedEl[i].logoSVGPositioning.bottom}; right: ${modifiedEl[i].logoSVGPositioning.right}"><use xlink:href="#logo-${modifiedEl[i].logoSVG}"/></svg>
          </div>
        </article>`;
      workWrapper.innerHTML += workElTemplate;
    }

    animate.setupIndexWaypoints();
    handlers.workElHandlers();
  }



  renderWorkPage(){
    let workElTemplate;
    let renderEl = render.pageData.work;
    let hash = window.location.hash.slice(1, window.location.hash.length);

    let work__title = document.querySelector(".hero__title");
    let work__subtitle = document.querySelector(".hero__subtitle");
    let work__copy = document.querySelector(".hero__work-copy");
    let work__copySub = document.querySelector(".hero__sub-description");
    let work__status = document.querySelector(".hero__sub-status");

    let work__wrapper = document.querySelector(".work__wrapper");

    for(let i = 0; i < renderEl.length; i++){
      if( renderEl[i].pagetitle == hash ){
        renderEl = renderEl[i];
        this.currentPageIndex = i;
      }
    }


    work__title.innerHTML = renderEl.workTitle;
    work__subtitle.innerHTML = "";

    for(let j = 0; j < renderEl.filters.length; j++){
      if( j <= 0 ){
        work__subtitle.innerHTML += renderEl.filters[j];
      }else{
        work__subtitle.innerHTML += ", " + renderEl.filters[j];
      }
    }

    work__copy.innerHTML = renderEl.workCopy;
    work__copySub.innerHTML = renderEl.workDescription;
    work__status.innerHTML = renderEl.workStatus;

    work__wrapper.innerHTML = "";

    for( let k = 0; k < renderEl.workEls.length; k++ ){

      if(renderEl.workEls[k].type == "img"){
        work__wrapper.innerHTML += `<img class="work__image work__image--hidden" src="${renderEl.workEls[k].url}" alt="${renderEl.workEls[k].alt}"/>`
      }

      if(renderEl.workEls[k].type == "video"){
        work__wrapper.innerHTML += `<div class="work__video-wrapper"><div class="work__video" id="${renderEl.workEls[k].videoName}"></div></div>`;
        let player;
        player = new YT.Player(renderEl.workEls[k].videoName, {
          videoId: renderEl.workEls[k].videoId
        });
    }

    }

    animate.setupWorkWaypoints();
  }


  renderAboutPage(){
    let workElTemplate;
    let renderEl = render.pageData.about;
    let aboutHero = document.querySelector(".hero__headline");
    let aboutCopy = document.querySelector(".copyblock__copy");
    let resumeLink = document.querySelector(".download__resume");

    aboutHero.innerHTML = renderEl.heroCopy;
    aboutCopy.innerHTML = renderEl.aboutCopy;
    resumeLink.setAttribute("href", renderEl.resumeLink)
  }


  renderContactPage(){
    let workElTemplate;
    let renderEl = render.pageData.contact;
    let contactHeadline = document.querySelector(".hero__headline");
    let contactSubline = document.querySelector(".hero__subline");
    contactHeadline.innerHTML = renderEl.headline;
    contactSubline.innerHTML = renderEl.subline;
  }

  renderMoreWorkEl(){
    let renderEl = render.pageData.work;
    let listEl = document.querySelector(".morework__listEl");

    if(this.currentPageIndex != null){
      // renderEl.splice(this.currentPageIndex,1);

      for(let k = 0; k < this.currentPageIndex + 1; k++){
        let newEl = renderEl.splice(0,1);
        renderEl.push(newEl[0]);
      }

    }

    listEl.innerHTML = "";

    for(let i = 0; i < renderEl.length; i++){

      let string = `<li class="morework__workEL" data-hash="/work.html#${renderEl[i].pagetitle}">
                      <a>
                      <span class="morework__titleWrap">
                        <h1>${renderEl[i].workTitle.toUpperCase()}</h1>
                      </span>
                      <h3>`;

       for(let j = 0; j < renderEl[i].filters.length; j++){
         if(j > 0){
            string += ", ";
         }
         string += renderEl[i].filters[j]
       }

       string += `</h3>
                  <figure class="morework__image">
                  <span class="morework__thumbWrap"><img class="morework__imageEl" alt="${renderEl[i].thumbnailAltText}" src="${renderEl[i].thumbnailImagePath}"/></span>
                  <svg class="morework__logo" style="width: ${renderEl[i].logoSVGPositioning.width}; height: ${renderEl[i].logoSVGPositioning.height}; bottom: ${renderEl[i].logoSVGPositioning.bottom}; right: ${renderEl[i].logoSVGPositioning.right}"><use xlink:href="#logo-${renderEl[i].logoSVG}"/></svg>
                </figure>
              </a>
            </li>`;

      listEl.innerHTML += string;

    }

    handlers.moreworkHandlers();

  }







}
